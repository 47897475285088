import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
import { CodeBlock } from "../../../src/components/DigitalGarden";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "Netlify"`}</p>



    <h2 {...{
      "id": "netlify",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#netlify",
        "aria-label": "netlify permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Netlify`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#prevent-netlify-from-treating-warnings-as-errors-during-the-build"
        }}>{`Prevent Netlify from treating warnings as errors during the build`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#react-page-not-found-when-using-react-router"
        }}>{`React - page not found when using React router`}</a></li>
    </ol>
    <h3 {...{
      "id": "prevent-netlify-from-treating-warnings-as-errors-during-the-build",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#prevent-netlify-from-treating-warnings-as-errors-during-the-build",
        "aria-label": "prevent netlify from treating warnings as errors during the build permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prevent Netlify from treating warnings as errors during the build`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Site settings -> Build & Deploy -> Continuous deployment -> Edit settings`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Enter Build command`}</p>
      </li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`CI='' <your_build_command>`}</CodeBlock>
    <p>{`Build command options:`}</p>
    <ul>
      <li parentName="ul">{`npm run build`}</li>
      <li parentName="ul">{`yarn build`}</li>
      <li parentName="ul">{`gatsby build`}</li>
    </ul>
    <p>{`and so on.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.netlify.com/configure-builds/troubleshooting-tips/#build-fails-on-warning-message"
      }}>{`Docs`}</a></p>
    <h3 {...{
      "id": "react---page-not-found-when-using-react-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#react---page-not-found-when-using-react-router",
        "aria-label": "react   page not found when using react router permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`React - page not found when using React router`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In the public folder of a project create file `}<em parentName="p">{`_`}{`redirects`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add the following rewrite rule`}</p>
      </li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`/*    /index.html   200`}</CodeBlock>
    <ul>
      <li parentName="ul">{`Redeploy your site`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://docs.netlify.com/routing/redirects/rewrites-proxies/#history-pushstate-and-single-page-apps"
      }}>{`Docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      